/* eslint-disable no-undef */
import React from "react"
import TemplatePageWhatsapp from "@components/pageChannels/pageWhatsapp/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
         Accede a la API para WhatsApp Business y solo paga por consumo [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/whatsapp-business-api/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te facilitamos el acceso a la API de WhatsApp Business para que puedas optimizar la atención al cliente de tu negocio y mejorar tus ventas."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Accede a la API para WhatsApp Business y solo paga por consumo"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/whatsapp-business-api/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Te facilitamos el acceso a la API de WhatsApp Business para que puedas optimizar la atención al cliente de tu negocio y mejorar tus ventas."
      />
    </Helmet>
    <TemplatePageWhatsapp location={location} />
  </div>
)

export default IndexPage
