import React from "react"

export const TitleUnderlineLeftOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="118" height="8" viewBox="0 0 118 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.938 8C19.4984 7.25735 1.63513 8.08646 0.336714 7.86265C-0.723318 7.56254 0.970705 2.27751 1.95466 1.49925C2.29955 1.21949 2.70023 1.17881 3.61825 1.30597C5.07389 1.54505 23.8958 1.07198 23.8958 1.07198C24.1443 1.23475 24.8899 0.685396 25.0268 0.883775C25.458 1.34666 55.9656 0.715913 58.5675 0.69048C60.1449 0.67522 58.8972 0.99059 60.7535 0.863424C64.7096 0.609092 73.3319 0.853252 76.3294 0.563314C78.7284 0.324241 79.2457 0.314075 81.5484 0.507368C83.9017 0.680314 96.4142 0.431066 97.8445 0.0597408C98.1944 -0.0419921 98.4632 -0.00638251 98.4632 0.11061C98.4632 0.136044 107.283 0.222513 107.324 0.314073C107.39 0.431066 107.659 0.487015 107.907 0.405629C108.369 0.283549 117.757 -0.15899 117.904 0.181815C118.365 1.15845 117.072 5.31932 115.982 6.33157C114.866 7.41502 108.739 6.19932 103.814 6.85041L80.884 6.91654L75.9693 7.32347C75.4063 6.97249 73.2406 7.85756 71.932 7.22682C71.3792 6.97757 67.4738 6.94196 66.6116 7.20647C65.8609 7.40994 45.8826 6.89618 43.8335 7.60323C40.1818 7.01318 25.3717 7.85757 21.938 8Z" fill="#FFA700" />
    </svg>

  </span>
)

export const TitleUnderlineLeftTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="251" height="8" viewBox="0 0 251 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6648 8C41.4755 7.25735 3.47811 8.08646 0.716231 7.86265C-1.53858 7.56254 2.0648 2.27751 4.15779 1.49925C4.89141 1.21949 5.74371 1.17881 7.69644 1.30597C10.7928 1.54505 50.8292 1.07198 50.8292 1.07198C51.3579 1.23475 52.9438 0.685396 53.2351 0.883775C54.1521 1.34666 119.045 0.715913 124.58 0.69048C127.935 0.67522 125.281 0.99059 129.23 0.863424C137.645 0.609092 155.986 0.853252 162.362 0.563314C167.465 0.324241 168.565 0.314075 173.463 0.507368C178.469 0.680314 205.084 0.431066 208.127 0.0597408C208.871 -0.0419921 209.443 -0.00638251 209.443 0.11061C209.443 0.136044 228.204 0.222513 228.291 0.314073C228.431 0.431066 229.003 0.487015 229.531 0.405629C230.513 0.283549 250.483 -0.15899 250.796 0.181815C251.777 1.15845 249.026 5.31932 246.707 6.33157C244.333 7.41502 231.301 6.19932 220.825 6.85041L172.05 6.91654L161.596 7.32347C160.398 6.97249 155.791 7.85756 153.008 7.22682C151.832 6.97757 143.525 6.94196 141.691 7.20647C140.094 7.40994 97.5977 6.89618 93.2391 7.60323C85.4714 7.01318 53.9687 7.85757 46.6648 8Z" fill="#FFA700" />
    </svg>
  </span>
)